const drivers = [
    { student_id: 1, name: "Ozzy Domarkas", time: 22.204 },
    { student_id: 2, name: "Jamie Doyle", time: 22.094 },
    { student_id: 3, name: "Rian Doyle", time: 22.168 },
    { student_id: 4, name: "Peter Stanev", time: 22.341 },
    { student_id: 5, name: "Jamie Tobin", time: 22.733 },
    { student_id: 6, name: "Hannah O'Connor", time: 22.395 },
    { student_id: 7, name: "Nathan McNamara", time: 24.325 },
    { student_id: 8, name: "Justin Malins", time: 22.574 },
    { student_id: 9, name: "Eoghan Dowling", time: 23.566 },
    { student_id: 10, name: "Darragh Whitney", time: 22.330 },
    { student_id: 11, name: "Dylan Shupe", time: 22.351 },
    { student_id: 12, name: "Ronan Duggan", time: 22.536 },
    { student_id: 13, name: "Laura Moroso", time: 25.416 },
    { student_id: 14, name: "Sam Boyle", time: 22.071 },
    { student_id: 15, name: "Ben Power", time: 22.361 },
    { student_id: 16, name: "Ryan Sexton", time: 22.806 },
    { student_id: 17, name: "Richie Murphy", time: 21.845 },
    { student_id: 18, name: "Ellen Donnelly", time: 22.249 },
    { student_id: 19, name: "Jamie Halloran", time: 22.413 },
    { student_id: 20, name: "Dylan Rockett", time: 23.035 },
    { student_id: 21, name: "Dean Horgan", time: 25.29 },
    { student_id: 22, name: "Niko Wisniewski", time: 22.591 },
    { student_id: 23, name: "Jack Gear", time: 21.953 },
    { student_id: 25, name: "Harry Watkin", time: 22.941 },
    { student_id: 26, name: "Adam Crales", time: 23.323 },
    { student_id: 27, name: "Patryk Pawlak", time: 23.795 },
    { student_id: 28, name: "Cillian O'Connor", time: 22.395 },
    { student_id: 29, name: "Simon Harte", time: 22.482 },
    { student_id: 30, name: "Leon Wallis", time: 22.989 },
    { student_id: 31, name: "Ryan Price", time: 23.282 },
    { student_id: 32, name: "Nathan Fitzgerald", time: 22.736 },
    { student_id: 33, name: "Josh Murphy", time: 22.561 },
    { student_id: 34, name: "Evan O'Neill", time: 22.985 },
    { student_id: 35, name: "Ryan Hodge", time: 24.159 },
    { student_id: 36, name: "Tomas Guiney", time: 23.465 },
    { student_id: 37, name: "Sarah Niedermayr", time: 26.236 },
    { student_id: 38, name: "Lydia Crotty", time: 26.836 },
    { student_id: 39, name: "Buse Hoggoren", time: 29.796 },
    { student_id: 40, name: "Jack Downey", time: 23.242 },
    { student_id: 41, name: "Ciara Lennox", time: 25.035 },
    { student_id: 42, name: "Simone", time: 29.588 },
    { student_id: 43, name: "James Walker", time: 23.849 },
    { student_id: 44, name: "Archy Sergejs", time: 22.525 },
    { student_id: 45, name: "Cillian O'Brien", time: 23.962 },
    { student_id: 46, name: "Noah Gibbons", time: 23.584 },
    { student_id: 47, name: "Liam Kenny", time: 24.886 },
    { student_id: 48, name: "Jack Mackey", time: 25.131 },
    { student_id: 49, name: "Marcus Wearen", time: 26.827 },
    { student_id: 50, name: "Rosanne Coe", time: 28.554 },
];

export default drivers;
